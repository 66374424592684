const nowOutput = `
<p><strong>What I'm doing now</strong><br />
<i>Updated <strong>September 10th, 2024</strong>, from my home in South Africa, where it's the start of Spring.</i></p>
<ol>
<li>Busy updating my website. App was previously written in vue.js busy upgrading it to React and theme overhaul.</li>
<li>My day job is consulting to a UX agenecy in the US. iiimpact.io. Busy closing off on a "AI Powered Third Party Risk and Diligence Solutions" project and about to begin work on a "Managed Detection and Response Services" for cyber security.</li>
<li>Ongoing promotion and creating videos for my apps. Building them was easy but marketing I find very challenging!</li>
<li>Learning to play Paddle Tennis</li>
<li>Summer is around the corner. Wind has begun blowing so soon be back on the water Kite Boarding"</li>
<li>Writing a new book</li>
</ol>

<hr>
  <p class="commands-text">Available commands: - help - about - now - apps - books - portfolio - paintings  - uiux - contact - code - taoism<br />themes (default, snow, mist, zen, tao, mountain)  - clear</p>
`;
export default nowOutput;